/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Component2 = ({ className, divClassName, text = "Product Owners" }) => {
  return (
    <div className={`component-2 ${className}`}>
      <div className={`product-owners ${divClassName}`}>{text}</div>
    </div>
  );
};

Component2.propTypes = {
  text: PropTypes.string,
};
