/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Component24 = ({
  property1,
  className,
  componentClassName,
  frameClassName,
  frameClassNameOverride,
  divClassName,
  divClassNameOverride,
  frameClassName1,
  groupWrapperClassName,
  groupClassName,
  vectorClassName,
  vector = "/img/vector-22.svg",
  divClassName1,
  frameClassName2,
  groupWrapperClassNameOverride,
  groupClassNameOverride,
  vectorClassNameOverride,
  img = "/img/vector-22.svg",
  acknowledgeYourClassName,
  frameClassName3,
  frameClassName4,
  vectorWrapperClassName,
  imgClassName,
  vector1 = "/img/vector-22.svg",
  employEmpiricalClassName,
  frameClassName5,
  frameClassName6,
  vectorWrapperClassNameOverride,
  imgClassNameOverride,
  vector2 = "/img/vector-17.svg",
  divClassName2,
  frameClassName7,
  frameClassName8,
  groupClassName1,
  vectorClassName1,
  vector3 = "/img/vector-17.svg",
  divClassName3,
  frameClassName9,
  frameClassName10,
  groupClassName2,
  vectorClassName2,
  vector4 = "/img/vector-17.svg",
  divClassName4,
  elementClassName,
  element = "/img/231138-1-3.png",
}) => {
  return (
    <div className={`component-24 ${className}`}>
      <div className={`frame-wrapper ${componentClassName}`}>
        <div className={`frame ${frameClassName}`}>
          <div className={`frame-2 ${frameClassNameOverride}`}>
            <div className={`text-wrapper-7 ${divClassName}`}>Learning Goals</div>
            <div className={`frame-3 ${divClassNameOverride}`}>
              <div className={`frame-4 ${frameClassName1}`}>
                <div className={`frame-5 ${groupWrapperClassName}`}>
                  <div className={`vector-wrapper ${groupClassName}`}>
                    <img className={`vector-2 ${vectorClassName}`} alt="Vector" src={vector} />
                  </div>
                </div>
                <p className={`p ${divClassName1}`}>
                  You will learn how to explain and facilitate the Scrum roles of team member, Product Owner, and
                  ScrumMaster in this course.
                </p>
              </div>
              <div className={`frame-6 ${frameClassName2}`}>
                <div className={`frame-5 ${groupWrapperClassNameOverride}`}>
                  <div className={`vector-wrapper ${groupClassNameOverride}`}>
                    <img className={`vector-2 ${vectorClassNameOverride}`} alt="Vector" src={img} />
                  </div>
                </div>
                <p className={`text-wrapper-8 ${acknowledgeYourClassName}`}>
                  Acknowledge your team&#39;s productivity and motivate them to greater productivity.
                </p>
              </div>
              <div className={`frame-7 ${frameClassName3}`}>
                <div className={`frame-5 ${frameClassName4}`}>
                  <div className={`vector-wrapper ${vectorWrapperClassName}`}>
                    <img className={`vector-2 ${imgClassName}`} alt="Vector" src={vector1} />
                  </div>
                </div>
                <p className={`p ${employEmpiricalClassName}`}>
                  Employ empirical reasoning in your project work. Use Scrum&#39;s fundamental, critical concepts.
                </p>
              </div>
              <div className={`frame-8 ${frameClassName5}`}>
                <div className={`frame-5 ${frameClassName6}`}>
                  <div className={`vector-wrapper ${vectorWrapperClassNameOverride}`}>
                    <img className={`vector-2 ${imgClassNameOverride}`} alt="Vector" src={vector2} />
                  </div>
                </div>
                <p className={`text-wrapper-8 ${divClassName2}`}>
                  Explain the significance of organisational agreement on software readiness.
                </p>
              </div>
              <div className={`frame-9 ${frameClassName7}`}>
                <div className={`frame-5 ${frameClassName8}`}>
                  <div className={`vector-wrapper ${groupClassName1}`}>
                    <img className={`vector-2 ${vectorClassName1}`} alt="Vector" src={vector3} />
                  </div>
                </div>
                <p className={`p ${divClassName3}`}>
                  Recognize that the ScrumMaster role can be both the most rewarding and the most difficult on a
                  project.
                </p>
              </div>
              <div className={`frame-10 ${frameClassName9}`}>
                <div className={`frame-5 ${frameClassName10}`}>
                  <div className={`vector-wrapper ${groupClassName2}`}>
                    <img className={`vector-2 ${vectorClassName2}`} alt="Vector" src={vector4} />
                  </div>
                </div>
                <p className={`p ${divClassName4}`}>
                  In Scrum, embrace the role of conflict resolution. Participate in real-world Scrum projects.
                </p>
              </div>
            </div>
          </div>
          <img
            className={`element ${property1} ${elementClassName}`}
            alt="Element"
            src={
              property1 === "frame-40442"
                ? "/img/231138-1-2.png"
                : property1 === "frame-40443"
                ? "/img/231138-1-1.png"
                : element
            }
          />
        </div>
      </div>
    </div>
  );
};

Component24.propTypes = {
  property1: PropTypes.oneOf(["frame-40441", "frame-40443", "frame-40442"]),
  vector: PropTypes.string,
  img: PropTypes.string,
  vector1: PropTypes.string,
  vector2: PropTypes.string,
  vector3: PropTypes.string,
  vector4: PropTypes.string,
  element: PropTypes.string,
};
