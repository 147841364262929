/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";

export const Paypal = ({
  property1,
  propertyPrimaryClassName,
  property = "/img/property-1-variant9.svg",
  propertyVariant = "/img/property-1-variant3-1.svg",
  propertyPrimary = "/img/property-1-primary-1.svg",
}) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "primary",
  });

  return (
    <>
      {(state.property1 === "default" ||
        state.property1 === "primary" ||
        state.property1 === "variant-10" ||
        state.property1 === "variant-3" ||
        state.property1 === "variant-4" ||
        state.property1 === "variant-5" ||
        state.property1 === "variant-6" ||
        state.property1 === "variant-7" ||
        state.property1 === "variant-8" ||
        state.property1 === "variant-9") && (
        <img
          className={`paypal property-primary ${propertyPrimaryClassName}`}
          alt="Property primary"
          src={
            state.property1 === "variant-3"
              ? propertyVariant
              : state.property1 === "variant-5"
              ? "/img/property-1-variant5-1.svg"
              : state.property1 === "variant-7"
              ? "/img/property-1-variant7-1.svg"
              : state.property1 === "variant-9"
              ? property
              : state.property1 === "variant-10"
              ? "/img/property-1-variant10-1.svg"
              : state.property1 === "variant-8"
              ? "/img/property-1-variant8.svg"
              : state.property1 === "variant-6"
              ? "/img/property-1-variant6-1.svg"
              : state.property1 === "variant-4"
              ? "/img/property-1-variant4-1.svg"
              : state.property1 === "default"
              ? "/img/property-1-default-6.svg"
              : propertyPrimary
          }
        />
      )}

      {["variant-11", "variant-12"].includes(state.property1) && (
        <div
          className={`paypal property ${state.property1} ${propertyPrimaryClassName}`}
          onMouseEnter={() => {
            dispatch("mouse_enter");
          }}
          onMouseLeave={() => {
            dispatch("mouse_leave");
          }}
        >
          <div className="discover-card-logo">
            <div className="ellipse-wrapper">
              <div className="ellipse" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        property1: "variant-12",
      };

    case "mouse_leave":
      return {
        ...state,
        property1: "variant-11",
      };
  }

  return state;
}

Paypal.propTypes = {
  property1: PropTypes.oneOf([
    "variant-12",
    "default",
    "variant-5",
    "variant-11",
    "variant-3",
    "primary",
    "variant-8",
    "variant-4",
    "variant-7",
    "variant-6",
    "variant-10",
    "variant-9",
  ]),
  property: PropTypes.string,
  propertyVariant: PropTypes.string,
  propertyPrimary: PropTypes.string,
};
