/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";

export const Component11 = ({ property1, className, groupClassName, overlapGroupClassName, divClassName }) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "frame-40245",
  });

  return (
    <div
      className={`component-11 property-1-2-${state.property1} ${className}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
    >
      {state.property1 === "frame-40245" && (
        <div className={`group-6 ${groupClassName}`}>
          <div className={`overlap-group-6 ${overlapGroupClassName}`}>
            <div className={`text-wrapper-6 ${divClassName}`}>Top 20</div>
          </div>
        </div>
      )}

      {state.property1 === "frame-40246" && (
        <>
          <div className="group-7">
            <div className="overlap-group-6">
              <div className="text-wrapper-6">Top 20</div>
            </div>
          </div>
          <img className="group-8" alt="Group" src="/img/group-237577-2.png" />
        </>
      )}
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        property1: "frame-40246",
      };

    case "mouse_leave":
      return {
        ...state,
        property1: "frame-40245",
      };
  }

  return state;
}

Component11.propTypes = {
  property1: PropTypes.oneOf(["frame-40245", "frame-40246"]),
};
