/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const ShopiconsRegularPlus9 = ({ className }) => {
  return (
    <svg
      className={`shopicons-regular-plus-9 ${className}`}
      fill="none"
      height="48"
      viewBox="0 0 48 48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g-6" clipPath="url(#clip0_6_8252)">
        <path className="path" d="M26 8H22V22H8V26H22V40H26V26H40V22H26V8Z" fill="black" />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_6_8252">
          <rect className="rect" fill="white" height="48" width="48" />
        </clipPath>
      </defs>
    </svg>
  );
};
