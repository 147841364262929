/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";

export const PropertyFrameWrapper = ({
  property1,
  className,
  groupClassName,
  overlapGroupClassName,
  divClassName,
  salaryMaleClassName,
  salaryMale = "/img/salary-male-1.png",
}) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "frame-40246",
  });

  return (
    <div
      className={`property-frame-wrapper property-1-0-${state.property1} ${className}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
    >
      <div className={`group-3 ${groupClassName}`}>
        {state.property1 === "frame-40246" && (
          <div className={`overlap-group-5 ${overlapGroupClassName}`}>
            <div className={`text-wrapper-5 ${divClassName}`}>24%</div>
            <img className={`salary-male ${salaryMaleClassName}`} alt="Salary male" src={salaryMale} />
          </div>
        )}

        {state.property1 === "frame-40245" && (
          <>
            <div className="group-4">
              <div className="overlap-group-5">
                <div className="text-wrapper-5">24%</div>
                <img className="salary-male" alt="Salary male" src="/img/salary-male-1.png" />
              </div>
            </div>
            <img className="group-5" alt="Group" src="/img/group-237577-1.png" />
          </>
        )}
      </div>
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        property1: "frame-40245",
      };

    case "mouse_leave":
      return {
        ...state,
        property1: "frame-40246",
      };
  }

  return state;
}

PropertyFrameWrapper.propTypes = {
  property1: PropTypes.oneOf(["frame-40245", "frame-40246"]),
  salaryMale: PropTypes.string,
};
