/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";

export const Component = ({
  property1,
  className,
  groupClassName,
  overlapGroupClassName,
  divClassName,
  newJobClassName,
  newJob = "/img/new-job-1.png",
}) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "frame-40245",
  });

  return (
    <div
      className={`component ${state.property1} ${className}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
    >
      <div className={`group ${groupClassName}`}>
        {state.property1 === "frame-40245" && (
          <div className={`overlap-group ${overlapGroupClassName}`}>
            <div className={`text-wrapper ${divClassName}`}>Top Job</div>
            <img className={`new-job ${newJobClassName}`} alt="New job" src={newJob} />
          </div>
        )}

        {state.property1 === "frame-40246" && (
          <>
            <div className="overlap-group-wrapper">
              <div className="overlap-group">
                <div className="text-wrapper">Top Job</div>
                <img className="new-job" alt="New job" src="/img/new-job-1.png" />
              </div>
            </div>
            <div className="group-wrapper">
              <div className="overlap-wrapper">
                <div className="overlap">
                  <div className="div">
                    <div className="overlap-group-2">
                      <div className="rectangle" />
                      <img className="vector" alt="Vector" src="/img/vector-6-1.svg" />
                    </div>
                  </div>
                  <div className="text-wrapper-2">Top Job roles</div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        property1: "frame-40246",
      };

    case "mouse_leave":
      return {
        ...state,
        property1: "frame-40245",
      };
  }

  return state;
}

Component.propTypes = {
  property1: PropTypes.oneOf(["frame-40245", "frame-40246"]),
  newJob: PropTypes.string,
};
