/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";

export const PropertyGroupWrapper = ({
  property1,
  className,
  groupClassName,
  overlapGroupClassName,
  divClassName,
  cashClassName,
  cash = "/img/cash-2.png",
}) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "group-237575",
  });

  return (
    <div
      className={`property-group-wrapper ${state.property1} ${className}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
    >
      <div className={`group-54 ${groupClassName}`}>
        {state.property1 === "group-237575" && (
          <div className={`overlap-group-3 ${overlapGroupClassName}`}>
            <div className={`text-wrapper-3 ${divClassName}`}>$116K</div>
            <img className={`cash ${cashClassName}`} alt="Cash" src={cash} />
          </div>
        )}

        {state.property1 === "group-237578" && (
          <>
            <div className="group-2">
              <div className="overlap-group-4">
                <div className="text-wrapper-4">$116K</div>
                <img className="cash" alt="Cash" src="/img/cash-1.png" />
              </div>
            </div>
            <img className="img" alt="Group" src="/img/group-237577.png" />
          </>
        )}
      </div>
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        property1: "group-237578",
      };

    case "mouse_leave":
      return {
        ...state,
        property1: "group-237575",
      };
  }

  return state;
}

PropertyGroupWrapper.propTypes = {
  property1: PropTypes.oneOf(["group-237575", "group-237578"]),
  cash: PropTypes.string,
};
