/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { Property1Default1 } from "../../icons/Property1Default1";
import "./style.css";

export const PropertyDefault = () => {
  return <Property1Default1 className="property-1-default-1" />;
};
