/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const DivWrapper = ({
  property1,
  className,
  overlapGroupClassName,
  rectangleClassName,
  rectangle = "/img/rectangle-738-3.svg",
  rectangleClassNameOverride,
  img = "/img/rectangle-739-3.svg",
  imgClassName,
  rectangle1 = "/img/rectangle-736-3.svg",
}) => {
  return (
    <div className={`div-wrapper ${className}`}>
      <div className={`overlap-group-7 ${overlapGroupClassName}`}>
        <img
          className={`rectangle-2 ${rectangleClassName}`}
          alt="Rectangle"
          src={
            property1 === "group-237584"
              ? "/img/rectangle-738-2.svg"
              : property1 === "group-237585"
              ? "/img/rectangle-738-1.svg"
              : rectangle
          }
        />
        <img
          className={`rectangle-3 ${rectangleClassNameOverride}`}
          alt="Rectangle"
          src={
            property1 === "group-237584"
              ? "/img/rectangle-739-2.svg"
              : property1 === "group-237585"
              ? "/img/rectangle-739-1.svg"
              : img
          }
        />
        <img
          className={`rectangle-4 ${imgClassName}`}
          alt="Rectangle"
          src={
            property1 === "group-237584"
              ? "/img/rectangle-736-2.svg"
              : property1 === "group-237585"
              ? "/img/rectangle-736-1.svg"
              : rectangle1
          }
        />
      </div>
    </div>
  );
};

DivWrapper.propTypes = {
  property1: PropTypes.oneOf(["group-237584", "group-237583", "group-237585"]),
  rectangle: PropTypes.string,
  img: PropTypes.string,
  rectangle1: PropTypes.string,
};
