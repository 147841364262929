import React, {useEffect} from "react";
import { CaretLeft } from "../../components/CaretLeft";
import { CaretRight } from "../../components/CaretRight";
import { Component } from "../../components/Component";
import { Component11 } from "../../components/Component11";
import { Component2 } from "../../components/Component2";
import { Component24 } from "../../components/Component24";
import { DivWrapper } from "../../components/DivWrapper";
import { ImgWrapper } from "../../components/ImgWrapper";
import { Paypal } from "../../components/Paypal";
import { Paypal1 } from "../../components/Paypal1";
import { Property1Default3Wrapper } from "../../components/Property1Default3Wrapper";
import { PropertyDefault } from "../../components/PropertyDefault";
import { PropertyDefaultWrapper } from "../../components/PropertyDefaultWrapper";
import { PropertyFrameWrapper } from "../../components/PropertyFrameWrapper";
import { PropertyGroupWrapper } from "../../components/PropertyGroupWrapper";
import { ShopiconsRegular } from "../../components/ShopiconsRegular";
import "./style.css";

export const SkillbookCaseStudy = () => {
  useEffect(() => {
    document.body.style.transform = `scale(${window.innerWidth / 1920})`;
    document.body.style.transformOrigin = `top left`;
  });
  return (
    <div className="skillbook-case-study">
      <div className="div-2">
        <div className="about">
          <div className="frame-11">
            <div className="frame-12">
              <div className="frame-13">
                <div className="text-wrapper-9">About:</div>
              </div>
            </div>
            <div className="skillbook-academy-an-wrapper">
              <p className="skillbook-academy-an">
                Skillbook Academy, an EdTech Platform focused in North American segment enables users to pursue their
                career goals with online and in-person courses that focus on Agile principles.
                <br />
                <br />
                Skillbook Academy has garnered a sterling reputation, underscored by its exceptional reviews on
                platforms such as Google and Trustpilot.
              </p>
            </div>
          </div>
          <div className="frame-14">
            <div className="text-wrapper-10">Brand Identity</div>
            <div className="text-wrapper-11">Logo Redesign</div>
            <div className="text-wrapper-11">UI-UX Design</div>
            <div className="text-wrapper-11">Micro Interactions</div>
            <div className="text-wrapper-11">Copywriting</div>
          </div>
        </div>
        <div className="mockup">
          <div className="overlap-2">
            <div className="rectangle-5" />
            <div className="edited-final-landing">
              <div className="frame-15">
                <div className="text-wrapper-12">Home</div>
                <img className="back" alt="Back" src="/img/back-1.png" />
                <div className="text-wrapper-13">Courses</div>
                <img className="back" alt="Back" src="/img/back.png" />
                <div className="text-wrapper-14">Scrum</div>
              </div>
              <div className="frame-16">
                <div className="frame-17">
                  <div className="frame-18">
                    <div className="certified-wrapper">
                      <p className="certified">Certified ScrumMaster (CSM)® Training &amp; Certification</p>
                    </div>
                    <div className="frame-19">
                      <p className="text-wrapper-15">
                        Accelerate your career by becoming a Certified ScrumMaster®. Our two-day CSM training course
                        will take you through everything you need to know about Scrum. Accelerate your career by
                        becoming a Certified ScrumMaster®. Our two-day CSM training course will take you through
                        everything you need to know about Scrum.
                      </p>
                    </div>
                    <div className="div-3">
                      <div className="frame-20">
                        <div className="div-3">
                          <div className="frame-21">
                            <img className="thumbs-up" alt="Thumbs up" src="/img/thumbs-up.png" />
                            <div className="text-wrapper-16">99% Exam Pass Rate</div>
                          </div>
                          <div className="frame-21">
                            <img
                              className="student-registration"
                              alt="Student registration"
                              src="/img/student-registration-1.png"
                            />
                            <div className="text-wrapper-16">10+ Industry Leading Trainers</div>
                          </div>
                        </div>
                      </div>
                      <div className="div-3">
                        <div className="frame-21">
                          <img className="audio" alt="Audio" src="/img/audio-2.png" />
                          <p className="text-wrapper-16">15+ Workshops in a Month</p>
                        </div>
                        <div className="frame-21">
                          <img className="audio" alt="Audio" src="/img/audio-1.png" />
                          <p className="text-wrapper-16">15+ Workshops in a Month</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-22">
                    <div className="frame-23">
                      <img className="group-9" alt="Group" src="/img/group-371.png" />
                      <img className="line" alt="Line" src="/img/line-26-1.svg" />
                      <img className="group-10" alt="Group" src="/img/group-379.png" />
                    </div>
                    <img
                      className="content-sai"
                      alt="Content sai"
                      src="/img/n7m6ux-content-sai-stacked-logo-4c-107c03g07c02800000m028-1.png"
                    />
                    <img
                      className="element-sa-badge"
                      alt="Element sa badge"
                      src="/img/51b099e6-sa-badge-rea-1-1050050000000000000028-1.png"
                    />
                  </div>
                </div>
                <div className="frame-24">
                  <div className="component-wrapper">
                    <div className="component-3">
                      <img className="play-circle-filled" alt="Play circle filled" src="/img/play-circle-filled.svg" />
                      <img className="csm-certified" alt="Csm certified" src="/img/csm-certified-1.png" />
                      <div className="best-seller-wrapper">
                        <div className="best-seller">BEST SELLER</div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-25">
                    <div className="frame-26">
                      <div className="frame-27">
                        <img
                          className="student-registration"
                          alt="Student registration"
                          src="/img/student-registration.png"
                        />
                        <div className="text-wrapper-17">1000+ students</div>
                      </div>
                      <div className="frame-28">
                        <img className="audio" alt="Audio" src="/img/audio.png" />
                        <div className="text-wrapper-17">English</div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-29">
                    <div className="frame-30">
                      <div className="frame-31">
                        <div className="frame-32">
                          <p className="starting-from">
                            <span className="span">Starting from</span>
                            <span className="text-wrapper-18">&nbsp;</span>
                            <span className="text-wrapper-19">$995</span>
                            <span className="text-wrapper-20">&nbsp;</span>
                          </p>
                        </div>
                        <img className="element-2" alt="Element" src="/img/445.svg" />
                      </div>
                    </div>
                    <div className="frame-33">
                      <div className="frame-34">
                        <p className="early-bird-tickets">
                          <span className="text-wrapper-21">Early Bird Tickets are </span>
                          <span className="text-wrapper-22">$500</span>
                          <span className="text-wrapper-21"> lower than Regular Price.</span>
                        </p>
                        <div className="frame-35">
                          <div className="frame-36">
                            <div className="text-wrapper-23">View all Schedule</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-37">
                <div className="group-11">
                  <div className="overlap-group-8">
                    <div className="group-12">
                      <div className="frame-38">
                        <div className="text-wrapper-24">Overview</div>
                        <div className="text-wrapper-25">Get Certified</div>
                        <div className="text-wrapper-26">Exam Details</div>
                        <div className="text-wrapper-26">Why Skillbook</div>
                        <div className="text-wrapper-26">Curriculum</div>
                        <div className="text-wrapper-26">Career Path</div>
                        <div className="text-wrapper-26">FAQs</div>
                      </div>
                      <img className="line-2" alt="Line" src="/img/line-1.svg" />
                    </div>
                    <div className="rectangle-6" />
                  </div>
                </div>
                <div className="frame-18">
                  <div className="text-wrapper-27">Course Summary</div>
                  <p className="text-wrapper-28">
                    The Certified ScrumMaster course (CSM®) is a foundational course on the Scrum framework and an entry
                    access to a high demand career in the agile world. To maximize the learning value for all types of
                    professionals, our workshops are designed as real-time scrum projects. Using interactive
                    assignments, role plays, simulations and many more fun learning activities, you will explore the
                    concepts of scrum and gain hands-on implementation experience.
                  </p>
                </div>
              </div>
              <div className="main-container">
                <div className="frame-39">
                  <div className="frame-40">
                    <img className="group-13" alt="Group" src="/img/group-425.png" />
                    <div className="text-wrapper-29">99%</div>
                  </div>
                  <div className="frame-41">
                    <p className="highest-pass-rate-in">
                      <span className="text-wrapper-30">Highest Pass Rate</span>
                      <span className="text-wrapper-31"> in the Industry</span>
                    </p>
                    <p className="if-you-are-not-able">
                      If you are not able to pass the exam in the first attempt, Skillbook Academy&nbsp;&nbsp;will allow
                      you take a reattempt for free!
                    </p>
                  </div>
                </div>
              </div>
              <div className="group-14">
                <div className="frame-42">
                  <img className="frame-43" alt="Frame" src="/img/vector-6.png" />
                  <div className="frame-44">
                    <div className="text-wrapper-32">Corporate</div>
                    <div className="text-wrapper-32">Testimonial</div>
                    <div className="text-wrapper-32">Instructors</div>
                    <div className="text-wrapper-32">Resources</div>
                    <div className="text-wrapper-32">Contact us</div>
                  </div>
                </div>
              </div>
              <div className="frame-45">
                <div className="frame-46">
                  <div className="frame-47">
                    <div className="frame-32">
                      <div className="tabs">
                        <div className="overlap-3">
                          <div className="text-wrapper-33">Key Benefits</div>
                          <div className="overlap-4">
                            <div className="frame-48">
                              <div className="frame-49" />
                              <p className="div-4">
                                <span className="text-wrapper-34">Average Salary: </span>
                                <span className="text-wrapper-35">$116K</span>
                              </p>
                            </div>
                            <div className="frame-50">
                              <div className="check-wrapper">
                                <img className="check" alt="Check" src="/img/check-19.png" />
                              </div>
                              <p className="div-4">
                                <span className="text-wrapper-34">Average Salary Growth: </span>
                                <span className="text-wrapper-35">24%</span>
                              </p>
                            </div>
                            <div className="frame-51">
                              <div className="frame-52" />
                              <p className="div-4">
                                <span className="text-wrapper-35">Top 20</span>
                                <span className="text-wrapper-34"> Highest Paying Jobs</span>
                              </p>
                            </div>
                            <div className="frame-53">
                              <div className="frame-54" />
                              <p className="div-4">
                                <span className="text-wrapper-35">Top companies</span>
                                <span className="text-wrapper-34"> Hiring</span>
                              </p>
                            </div>
                            <div className="frame-55">
                              <div className="frame-54" />
                              <p className="div-4">
                                <span className="text-wrapper-35">Top companies</span>
                                <span className="text-wrapper-34"> Hiring</span>
                              </p>
                            </div>
                            <div className="frame-56">
                              <div className="overlap-5">
                                <Component
                                  className="component-10"
                                  divClassName="design-component-instance-node"
                                  groupClassName="component-instance"
                                  newJob="/img/new-job.png"
                                  newJobClassName="component-4"
                                  overlapGroupClassName="component-10-instance"
                                  property1="frame-40245"
                                />
                                <img className="vector-3" alt="Vector" src="/img/vector-16.svg" />
                                <PropertyGroupWrapper
                                  cash="/img/cash.png"
                                  cashClassName="component-7"
                                  className="component-9"
                                  divClassName="component-6"
                                  groupClassName="component-9-instance"
                                  overlapGroupClassName="component-5"
                                  property1="group-237575"
                                />
                                <img className="group-15" alt="Group" src="/img/group-3.png" />
                                <div className="text-wrapper-36">Top 20</div>
                                <div className="top-companies">
                                  Top
                                  <br />
                                  companies
                                </div>
                                <PropertyFrameWrapper
                                  className="component-12"
                                  divClassName="component-13"
                                  groupClassName="component-12-instance"
                                  overlapGroupClassName="component-8"
                                  property1="frame-40246"
                                  salaryMale="/img/salary-male.png"
                                  salaryMaleClassName="component-14"
                                />
                                <Component11
                                  className="component-11-instance"
                                  divClassName="component-17"
                                  groupClassName="component-15"
                                  overlapGroupClassName="component-16"
                                  property1="frame-40245"
                                />
                              </div>
                              <img className="receive-cash" alt="Receive cash" src="/img/receive-cash.png" />
                              <img className="group-of-companies" alt="Group of companies" src="/img/vector-6.png" />
                            </div>
                          </div>
                          <div className="rectangle-7" />
                          <div className="text-wrapper-37">Top Job roles</div>
                          <p className="text-wrapper-38">Lorem, Lorem, Lorem, Lorem, Lorem, Lorem</p>
                          <p className="text-wrapper-39">Lorem, Lorem, Lorem, Lorem, Lorem, Lorem</p>
                        </div>
                      </div>
                      <div className="frame-57">
                        <div className="frame-58">
                          <div className="frame-59">
                            <div className="tab">
                              <div className="text-wrapper-40">Organizational Benefits</div>
                            </div>
                            <div className="tab-2">
                              <div className="text-wrapper-41">Individual Benefits</div>
                            </div>
                          </div>
                          <div className="group-16">
                            <div className="frame-60">
                              <img className="group-17" alt="Group" src="/img/group.png" />
                              <div className="frame-61">
                                <div className="frame-62">
                                  <p className="text-wrapper-42">Learn and practise Scrum principles and skills</p>
                                </div>
                              </div>
                            </div>
                            <div className="frame-63">
                              <img className="group-18" alt="Group" src="/img/group-421.png" />
                              <div className="frame-64">
                                <div className="frame-65">
                                  <div className="text-wrapper-43">Boost Your Professional Career</div>
                                </div>
                              </div>
                            </div>
                            <div className="frame-66">
                              <img className="vector-4" alt="Vector" src="/img/vector-15.svg" />
                              <div className="frame-61">
                                <div className="frame-67">
                                  <p className="text-wrapper-44">Enhance Team Collaboration and Leadership</p>
                                </div>
                              </div>
                            </div>
                            <div className="frame-68">
                              <img className="group-19" alt="Group" src="/img/group-422.png" />
                              <div className="frame-69">
                                <div className="frame-67">
                                  <div className="ensure-effective">
                                    Ensure Effective Agile <br />
                                    Implementation
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="frame-70">
                              <img className="group-20" alt="Group" src="/img/group-420.png" />
                              <div className="frame-61">
                                <div className="frame-67">
                                  <div className="text-wrapper-45">Tweak Your Mindset</div>
                                </div>
                              </div>
                            </div>
                            <div className="frame-71">
                              <img className="vector-5" alt="Vector" src="/img/vector-14.svg" />
                              <div className="frame-72">
                                <div className="ease-the-adoption-of-wrapper">
                                  <p className="ease-the-adoption-of">
                                    Ease the adoption of Agile in <br />
                                    your organization
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-73">
                        <div className="frame-74">
                          <div className="frame-75">
                            <div className="frame-76">
                              <div className="frame-77">
                                <div className="text-wrapper-46">Trusted</div>
                                <p className="become-an-instructor">
                                  Become an instructor, inspire change and change lives — including your own. The best
                                  part, You won’t have to do it alone. We’ll provide all the tools and skills to teach
                                  what you love and take it in front of people that need it.
                                </p>
                              </div>
                              <div className="frame-78">
                                <div className="frame-79">
                                  <div className="text-wrapper-47">1000+</div>
                                  <div className="text-wrapper-48">Students</div>
                                </div>
                                <div className="frame-67">
                                  <div className="text-wrapper-49">50+</div>
                                  <div className="text-wrapper-48">Instructors</div>
                                </div>
                                <div className="frame-67">
                                  <div className="text-wrapper-50">20+</div>
                                  <div className="text-wrapper-48">Courses</div>
                                </div>
                                <div className="frame-67">
                                  <div className="text-wrapper-51">2000+</div>
                                  <div className="text-wrapper-48">Online reviews</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-80">
                            <div className="frame-81">
                              <div className="frame-82">
                                <div className="frame-83">
                                  <img className="rectangle-8" alt="Rectangle" src="/img/rectangle-125.png" />
                                  <div className="div-3">
                                    <div className="text-wrapper-52">Sharla Wallace</div>
                                    <div className="frame-84">
                                      <img
                                        className="google-g-logo"
                                        alt="Google g logo"
                                        src="/img/google-g-logo-2-1.png"
                                      />
                                      <div className="frame-85">
                                        <img className="star" alt="Star" src="/img/star-1.png" />
                                        <div className="text-wrapper-53">4.5 / 5</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <p className="text-wrapper-54">
                                  I had an awesome experience completing the Certified ScrumMaster course with Skillbook
                                  Academy. The training was very well organized and the organizers were on site to
                                  answer any questions and provide additional support. I strongly recommend Skillbook
                                  for any corporate training needs.
                                </p>
                              </div>
                            </div>
                            <div className="frame-86">
                              <div className="frame-87">
                                <div className="frame-83">
                                  <img className="rectangle-8" alt="Rectangle" src="/img/rectangle-129.png" />
                                  <div className="div-3">
                                    <div className="text-wrapper-52">Sharla Wallace</div>
                                    <div className="frame-84">
                                      <img
                                        className="google-g-logo"
                                        alt="Google g logo"
                                        src="/img/google-g-logo-2.png"
                                      />
                                      <div className="frame-85">
                                        <img className="star" alt="Star" src="/img/star.png" />
                                        <div className="text-wrapper-53">4.5 / 5</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <p className="text-wrapper-55">
                                  I had an awesome experience completing the Certified ScrumMaster course with Skillbook
                                  Academy. The training was very well organized and the organizers were on site to
                                  answer any questions and provide additional support. I strongly recommend Skillbook
                                  for any corporate training needs.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="frame-88">
                            <div className="frame-89">
                              <div className="frame-26">
                                <div className="ellipse-2" />
                                <div className="ellipse-3" />
                                <div className="ellipse-3" />
                                <div className="ellipse-3" />
                                <div className="ellipse-3" />
                              </div>
                              <div className="frame-90">
                                <div className="caret-left-wrapper">
                                  <CaretLeft />
                                </div>
                                <div className="caret-right-wrapper">
                                  <CaretRight />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-91">
                            <div className="text-wrapper-56">Read All Testimonials</div>
                            <CaretRight />
                          </div>
                        </div>
                      </div>
                      <div className="frame-92">
                        <div className="frame-93">
                          <div className="frame-94">
                            <div className="group-21">
                              <div className="overlap-group-9">
                                <div className="overlap-6">
                                  <img className="sign-up" alt="Sign up" src="/img/vector-6.png" />
                                  <div className="overlap-7">
                                    <img className="vector-6" alt="Vector" src="/img/vector-6.png" />
                                    <img className="vector-7" alt="Vector" src="/img/vector-6.png" />
                                  </div>
                                </div>
                                <div className="overlap-8">
                                  <img className="vector-8" alt="Vector" src="/img/vector-6.png" />
                                  <img className="vector-9" alt="Vector" src="/img/vector-6.png" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-24">
                            <div className="frame-67">
                              <div className="text-wrapper-57">Get Certified</div>
                              <p className="steps-to-become-a">
                                <span className="text-wrapper-35">Steps to become a </span>
                                <span className="text-wrapper-58">CSM</span>
                              </p>
                            </div>
                            <div className="frame-95">
                              <div className="frame-96">
                                <div className="frame-54">
                                  <div className="text-wrapper-59">1</div>
                                </div>
                                <p className="sign-up-for-a-CSM">
                                  <span className="text-wrapper-34">Sign up for a </span>
                                  <span className="text-wrapper-60">CSM course</span>
                                </p>
                              </div>
                              <div className="frame-96">
                                <div className="frame-54">
                                  <div className="text-wrapper-61">2</div>
                                </div>
                                <p className="participate-in-the">
                                  <span className="text-wrapper-34">Participate in the </span>
                                  <span className="text-wrapper-60">2 days CSM workshop</span>
                                </p>
                              </div>
                              <div className="frame-96">
                                <div className="frame-54">
                                  <div className="text-wrapper-61">3</div>
                                </div>
                                <p className="pass-an-online">
                                  <span className="text-wrapper-34">Pass an </span>
                                  <span className="text-wrapper-60">online examination</span>
                                </p>
                              </div>
                              <div className="frame-96">
                                <div className="frame-54">
                                  <div className="text-wrapper-61">4</div>
                                </div>
                                <p className="receive-your">
                                  <span className="text-wrapper-34">Receive your </span>
                                  <span className="text-wrapper-60">certification</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-97">
                        <div className="frame-98">
                          <div className="frame-24">
                            <div className="text-wrapper-62">What you Learn</div>
                            <div className="frame-18">
                              <div className="frame-99">
                                <div className="frame-54">
                                  <img className="check-2" alt="Check" src="/img/vector-6.png" />
                                </div>
                                <p className="div-5">
                                  <span className="text-wrapper-60">Why Agile: </span>
                                  <span className="text-wrapper-63">
                                    Agile/Scrum Foundation – the definition of Agile
                                  </span>
                                </p>
                              </div>
                              <div className="frame-99">
                                <div className="frame-54">
                                  <img className="check-3" alt="Check" src="/img/vector-6.png" />
                                </div>
                                <p className="div-5">
                                  <span className="text-wrapper-60">Scrum Theory: </span>
                                  <span className="text-wrapper-63">Transparency, Inspection and Adaptation</span>
                                </p>
                              </div>
                              <div className="frame-99">
                                <div className="frame-54">
                                  <img className="check-4" alt="Check" src="/img/vector-6.png" />
                                </div>
                                <p className="div-5">
                                  <span className="text-wrapper-60">Scrum Framework:</span>
                                  <span className="text-wrapper-63"> The meetings, artifacts and roles</span>
                                </p>
                              </div>
                              <div className="frame-99">
                                <div className="frame-54">
                                  <img className="check-5" alt="Check" src="/img/vector-6.png" />
                                </div>
                                <p className="scrum-practices">
                                  <span className="text-wrapper-60">Scrum Practices:</span>
                                  <span className="text-wrapper-63">&nbsp;&nbsp;</span>
                                  <span className="text-wrapper-64">
                                    Product Vision, Estimation, Sprint, Backlog and release planning
                                  </span>
                                </p>
                              </div>
                              <div className="frame-99">
                                <div className="frame-54">
                                  <img className="check-6" alt="Check" src="/img/vector-6.png" />
                                </div>
                                <p className="div-5">
                                  <span className="text-wrapper-60">Scrum Leadership:</span>
                                  <span className="text-wrapper-65">
                                    {" "}
                                    What is it and why is it important for a ScrumMaster
                                  </span>
                                </p>
                              </div>
                              <div className="frame-100">
                                <div className="frame-54">
                                  <img className="check-7" alt="Check" src="/img/vector-6.png" />
                                </div>
                                <p className="people-management">
                                  <span className="text-wrapper-60">People Management:</span>
                                  <span className="text-wrapper-64">
                                    {" "}
                                    Helpful tips and techniques to resolve conflicts for building strong teams
                                  </span>
                                </p>
                              </div>
                              <div className="frame-100">
                                <div className="frame-54">
                                  <img className="check-8" alt="Check" src="/img/vector-6.png" />
                                </div>
                                <p className="div-5">
                                  <span className="text-wrapper-60">Sprint &amp; Release Planning: </span>
                                  <span className="text-wrapper-65">What is done for each and how to do it</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <DivWrapper
                            className="component-13-instance"
                            img="/img/vector-6.png"
                            imgClassName="component-21"
                            overlapGroupClassName="component-18"
                            property1="group-237583"
                            rectangle="/img/vector-6.png"
                            rectangle1="/img/vector-6.png"
                            rectangleClassName="component-19"
                            rectangleClassNameOverride="component-20"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="frame-101">
                      <div className="text-wrapper-66">What you Earn</div>
                      <div className="frame-102">
                        <div className="frame-103">
                          <div className="frame-104">
                            <div className="frame-105">
                              <div className="frame-83">
                                <img className="group-22" alt="Group" src="/img/vector-6.png" />
                                <p className="get-trained-by">
                                  <span className="text-wrapper-21">Get trained by globally acclaimed </span>
                                  <span className="text-wrapper-67">Certified Scrum Trainers (CSTs)</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="frame-106">
                            <div className="frame-107">
                              <div className="frame-83">
                                <img className="pen-nib" alt="Pen nib" src="/img/vector-6.png" />
                                <p className="div-6">
                                  <span className="text-wrapper-67">Experiential learning</span>
                                  <span className="text-wrapper-21">
                                    {" "}
                                    with Case studies, Scrum Activities, Role plays, &amp; Simulations
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="frame-104">
                            <div className="frame-108">
                              <div className="frame-83">
                                <img className="group-23" alt="Group" src="/img/vector-6.png" />
                                <p className="div-6">
                                  <span className="text-wrapper-67">Two-year membership</span>
                                  <span className="text-wrapper-21">
                                    {" "}
                                    in <br />
                                    Scrum Alliance
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-103">
                          <div className="frame-104">
                            <div className="frame-109">
                              <div className="frame-110">
                                <img className="group-24" alt="Group" src="/img/vector-6.png" />
                                <p className="div-6">
                                  <span className="text-wrapper-67">Two-year membership</span>
                                  <span className="text-wrapper-21">
                                    {" "}
                                    in <br />
                                    Scrum Alliance
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="frame-106">
                            <div className="frame-105">
                              <div className="frame-83">
                                <img className="group-25" alt="Group" src="/img/vector-6.png" />
                                <p className="get-trained-by">
                                  <span className="text-wrapper-21">Get trained by globally acclaimed </span>
                                  <span className="text-wrapper-67">Certified Scrum Trainers (CSTs)</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="frame-111">
                            <div className="frame-112">
                              <div className="frame-83">
                                <img className="pen-nib-2" alt="Pen nib" src="/img/vector-6.png" />
                                <p className="element-pdus-and-seus">
                                  <span className="text-wrapper-67">16 PDUs</span>
                                  <span className="text-wrapper-68"> and </span>
                                  <span className="text-wrapper-69">
                                    SEUs
                                    <br />
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-113">
                      <div className="frame-114">
                        <div className="frame-32">
                          <div className="frame-115">
                            <div className="text-wrapper-70">Who should attend</div>
                            <p className="this-course-is-ideal">
                              This course is ideal for any individual who wants <br />
                              to succeed in scrum, such as but not limited to <br />
                              the roles listed below
                            </p>
                          </div>
                          <div className="buttons">
                            <div className="text-wrapper-71">Register Now</div>
                          </div>
                        </div>
                        <div className="frame-116">
                          <div className="frame-117">
                            <Component2 className="component-2-instance" divClassName="component-22" />
                            <Component2
                              className="component-2-instance"
                              divClassName="component-22"
                              text="Product Managers"
                            />
                            <Component2
                              className="component-2-instance"
                              divClassName="component-22"
                              text="Project Managers"
                            />
                          </div>
                          <div className="frame-117">
                            <Component2
                              className="component-2-instance"
                              divClassName="component-22"
                              text="Business Analysts"
                            />
                            <Component2
                              className="component-2-instance"
                              divClassName="component-22"
                              text="Scrum Master"
                            />
                            <Component2
                              className="component-2-instance"
                              divClassName="component-22"
                              text="Team Leads"
                            />
                          </div>
                          <div className="frame-117">
                            <Component2
                              className="component-2-instance"
                              divClassName="component-23"
                              text="IT Operation"
                            />
                            <Component2
                              className="component-2-instance"
                              divClassName="component-23"
                              text="Process Leads"
                            />
                            <Component2
                              className="component-2-instance"
                              divClassName="component-23"
                              text="Programmer"
                            />
                            <Component2 className="component-2-instance" divClassName="component-23" text="Testers" />
                          </div>
                          <div className="frame-118">
                            <Component2
                              className="component-2-instance"
                              divClassName="component-23"
                              text="Data Analysts"
                            />
                            <Component2 className="component-2-instance" divClassName="component-23" text="Analysts" />
                            <Component2 className="component-2-instance" divClassName="component-23" text="Directors" />
                            <Component2 className="component-2-instance" divClassName="component-23" text="Managers" />
                            <Component2 className="component-2-instance" divClassName="component-23" text="CEO" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-119">
                    <div className="frame-120">
                      <div className="frame-32">
                        <div className="text-wrapper-57">Exam Details</div>
                        <div className="frame-121">
                          <div className="text-wrapper-72">Prerequisite</div>
                          <p className="text-wrapper-73">
                            There are no prerequisites or eligibility requirements for this certification. The two
                            necessary requirements are that one should take CSM certification training and successfully
                            pass the CSM certification exam.
                          </p>
                        </div>
                      </div>
                      <div className="frame-122">
                        <div className="text-wrapper-74">Exam Format</div>
                        <div className="frame-96">
                          <div className="frame-54">
                            <img className="check-9" alt="Check" src="/img/vector-6.png" />
                          </div>
                          <p className="div-7">
                            <span className="text-wrapper-75">1 hour</span>
                            <span className="text-wrapper-68"> online exam - open book</span>
                          </p>
                        </div>
                        <div className="frame-96">
                          <div className="frame-54">
                            <img className="check-10" alt="Check" src="/img/vector-6.png" />
                          </div>
                          <p className="div-7">
                            <span className="text-wrapper-68">Total of </span>
                            <span className="text-wrapper-75">50</span>
                            <span className="text-wrapper-68"> Multiple choice questions</span>
                          </p>
                        </div>
                        <div className="frame-96">
                          <div className="frame-54">
                            <img className="check-11" alt="Check" src="/img/vector-6.png" />
                          </div>
                          <p className="you-must-score-at">
                            <span className="text-wrapper-68">You must score at least </span>
                            <span className="text-wrapper-75">74%</span>
                            <span className="text-wrapper-68"> to pass the exam &amp; get certified</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-123">
                    <div className="frame-124">
                      <div className="frame-34">
                        <div className="text-wrapper-57">Why Skillbook</div>
                        <div className="text-wrapper-76">Skillbook Advantage</div>
                      </div>
                      <div className="frame-125">
                        <div className="frame-126">
                          <div className="frame-127">
                            <img className="clock" alt="Clock" src="/img/vector-6.png" />
                            <div className="frame-62">
                              <div className="text-wrapper-77">99% success guaranteed</div>
                              <p className="text-wrapper-78">
                                Our CSM training program is designed to help you reach your goals with a 99% success
                                rate.
                              </p>
                            </div>
                          </div>
                          <div className="frame-127">
                            <img className="ok" alt="Ok" src="/img/vector-6.png" />
                            <div className="frame-67">
                              <p className="text-wrapper-79">An Esteemed Panel of CSTs</p>
                              <p className="text-wrapper-78">
                                Engage with CSTs who are both renowned and active Scrum practitioners.
                              </p>
                            </div>
                          </div>
                          <div className="frame-127">
                            <img className="diploma" alt="Diploma" src="/img/vector-6.png" />
                            <div className="frame-67">
                              <div className="text-wrapper-80">Creative Workshops</div>
                              <p className="text-wrapper-81">
                                Gain valuable insight from accredited industry experts and take part in engaging
                                in-class activities and simulations.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="frame-128">
                          <div className="frame-129">
                            <img className="online" alt="Online" src="/img/vector-6.png" />
                            <div className="frame-62">
                              <div className="text-wrapper-82">Mentorship by industry experts</div>
                              <p className="text-wrapper-78">
                                Get valuable insights, tips and techniques from highly qualified mentors with industry
                                experience.
                              </p>
                            </div>
                          </div>
                          <div className="frame-130">
                            <img className="membership-card" alt="Membership card" src="/img/vector-6.png" />
                            <div className="frame-67">
                              <div className="text-wrapper-83">GLOBAL REA</div>
                              <p className="skillbook-is-a-scrum">
                                Skillbook is a Scrum Alliance Registered Education Ally (REA). We&#39;ve trained
                                thousands of professionals across 100+ countries.
                              </p>
                            </div>
                          </div>
                          <div className="frame-130">
                            <img className="membership-card-2" alt="Membership card" src="/img/vector-6.png" />
                            <div className="frame-67">
                              <div className="text-wrapper-83">GLOBAL REA</div>
                              <p className="skillbook-is-a-scrum">
                                Skillbook is a Scrum Alliance Registered Education Ally (REA). We&#39;ve trained
                                thousands of professionals across 100+ countries.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-131">
                  <div className="group-26">
                    <div className="frame-132">
                      <div className="frame-133">
                        <div className="text-wrapper-84">Skillbook’s Interactive Learning</div>
                        <div className="text-wrapper-85">Come prepared to participate</div>
                        <p className="text-wrapper-86">
                          In this training session, you can expect a very interactive experience. Our wholly immersive
                          training style means you’ll dig deep into real-world situations, learn by exploring, have fun,
                          practice new skills, and remember more of the course content.
                        </p>
                      </div>
                      <div className="frame-134">
                        <div className="frame-135">
                          <div className="frame-136">
                            <div className="group-27">
                              <div className="overlap-9">
                                <img className="group-28" alt="Group" src="/img/vector-6.png" />
                              </div>
                              <div className="frame-137">
                                <div className="avatar-w-photo">
                                  <div className="outside-stroke" />
                                </div>
                                <div className="outside-stroke-wrapper">
                                  <div className="outside-stroke" />
                                </div>
                                <div className="outside-stroke-wrapper">
                                  <div className="outside-stroke" />
                                </div>
                                <div className="outside-stroke-wrapper">
                                  <div className="outside-stroke" />
                                </div>
                                <div className="avatar-w-photo-2">
                                  <div className="overlap-group-10">
                                    <div className="text-wrapper-87">+15</div>
                                  </div>
                                </div>
                              </div>
                              <div className="group-29">
                                <div className="overlap-10">
                                  <img className="ellipse-4" alt="Ellipse" src="/img/vector-6.png" />
                                  <img
                                    className="emoji-smiling-face"
                                    alt="Emoji smiling face"
                                    src="/img/vector-6.png"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="group-30">
                              <div className="frame-138">
                                <div className="text-wrapper-88">Video Conferencing Tool</div>
                                <p className="text-wrapper-89">We utilize the best of Zoom for our workshops</p>
                                <div className="frame-139">
                                  <div className="frame-140">
                                    <img className="check-12" alt="Check" src="/img/vector-6.png" />
                                  </div>
                                  <p className="text-wrapper-90">
                                    Breakout rooms allow highly engaging group activities
                                  </p>
                                </div>
                                <div className="frame-139">
                                  <div className="frame-140">
                                    <img className="check-13" alt="Check" src="/img/vector-6.png" />
                                  </div>
                                  <p className="text-wrapper-90">
                                    Raise-hand feature will enable attendees to indicate their needs
                                  </p>
                                </div>
                                <div className="frame-139">
                                  <div className="frame-140">
                                    <img className="check-14" alt="Check" src="/img/vector-6.png" />
                                  </div>
                                  <div className="text-wrapper-90">Group &amp; Private chat</div>
                                </div>
                                <div className="frame-139">
                                  <div className="frame-140">
                                    <img className="check-15" alt="Check" src="/img/vector-6.png" />
                                  </div>
                                  <div className="text-wrapper-90">High-quality video resolution</div>
                                </div>
                                <div className="frame-139">
                                  <div className="frame-140">
                                    <img className="check-16" alt="Check" src="/img/vector-6.png" />
                                  </div>
                                  <div className="text-wrapper-90">Noise cancellation</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <p className="text-wrapper-91">Optimum Tools for Optimum learning</p>
                          <div className="frame-141">
                            <div className="frame-121">
                              <div className="text-wrapper-88">Group Collaboration Tool</div>
                              <p className="text-wrapper-92">
                                We use Miro or Mural for the finest collaborative experience
                              </p>
                              <div className="frame-139">
                                <div className="frame-140">
                                  <img className="check-17" alt="Check" src="/img/vector-6.png" />
                                </div>
                                <div className="text-wrapper-90">Online Shared Whiteboard</div>
                              </div>
                              <div className="frame-139">
                                <div className="frame-140">
                                  <img className="check-18" alt="Check" src="/img/vector-6.png" />
                                </div>
                                <div className="text-wrapper-90">Real-Time Collaboration</div>
                              </div>
                              <div className="frame-139">
                                <div className="frame-140">
                                  <img className="check-19" alt="Check" src="/img/vector-6.png" />
                                </div>
                                <div className="text-wrapper-90">Offers multiple software integrations</div>
                              </div>
                              <div className="frame-139">
                                <div className="frame-140">
                                  <img className="check-20" alt="Check" src="/img/vector-6.png" />
                                </div>
                                <div className="text-wrapper-90">Secure and Fast</div>
                              </div>
                            </div>
                            <img className="group-31" alt="Group" src="/img/vector-6.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-142">
                    <div className="frame-143">
                      <div className="frame-124">
                        <div className="frame-144">
                          <div className="text-wrapper-93">Meet your instructors</div>
                          <p className="text-wrapper-94">
                            Unlike typical professors, our instructors come from top companies at top positions and have
                            demonstrated leadership and expertise in their professions.
                          </p>
                        </div>
                        <div className="frame-99">
                          <div className="mentor">
                            <img className="rectangle-9" alt="Rectangle" src="/img/vector-6.png" />
                            <div className="group-32">
                              <div className="frame-145">
                                <div className="text-wrapper-95">Cathy Simpson</div>
                                <p className="text-wrapper-96">
                                  Certified Scrum Trainer &amp; Agile Coach California, United States
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="mentor">
                            <img className="rectangle-10" alt="Rectangle" src="/img/vector-6.png" />
                            <div className="group-33">
                              <div className="frame-145">
                                <div className="text-wrapper-97">Steve Spearman</div>
                                <p className="text-wrapper-96">Certified Scrum Trainer (CST®), Agile Coach</p>
                              </div>
                            </div>
                          </div>
                          <div className="mentor-cards">
                            <img className="rectangle-11" alt="Rectangle" src="/img/vector-6.png" />
                            <div className="group-34">
                              <div className="frame-145">
                                <div className="text-wrapper-97">Harry Narang</div>
                                <div className="text-wrapper-98">SPC®, Author, Speaker, Consultant</div>
                              </div>
                            </div>
                          </div>
                          <img className="rectangle-12" alt="Rectangle" src="/img/vector-6.png" />
                          <div className="frame-146">
                            <div className="text-wrapper-97">Ram Srinivasan</div>
                            <p className="text-wrapper-98">Certified Scrum Trainer (CST®), Agile Coach</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-147">
                      <div className="frame-89">
                        <div className="frame-26">
                          <div className="ellipse-2" />
                          <div className="ellipse-3" />
                          <div className="ellipse-3" />
                          <div className="ellipse-3" />
                          <div className="ellipse-3" />
                        </div>
                        <div className="frame-90">
                          <div className="caret-left-wrapper">
                            <CaretLeft />
                          </div>
                          <div className="caret-right-wrapper">
                            <CaretRight />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-148">
                  <div className="frame-149">
                    <div className="frame-150">
                      <div className="frame-150">
                        <div className="frame-151">
                          <div className="text-wrapper-99">Curriculum</div>
                          <div className="text-wrapper-100">Topics covered</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-152">
                      <div className="col-md">
                        <div className="card">
                          <img className="fixed-width" alt="Fixed width" src="/img/vector-6.png" />
                          <p className="feature-title">Introducing the Scaled Agile Framework (SAFe)</p>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="card">
                          <img className="fixed-width-2" alt="Fixed width" src="/img/vector-6.png" />
                          <p className="h-feature-title">Experiencing Program Increment (PI) Planning</p>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="card">
                          <img className="fixed-width-3" alt="Fixed width" src="/img/vector-6.png" />
                          <div className="feature-title-2">Scrum lifecycle</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-152">
                      <div className="col-md">
                        <div className="card">
                          <img className="fixed-width" alt="Fixed width" src="/img/vector-6.png" />
                          <div className="feature-title-2">Scrum lifecycle</div>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="card">
                          <img className="fixed-width-2" alt="Fixed width" src="/img/vector-6.png" />
                          <div className="feature-title-2">Scrum lifecycle</div>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="card">
                          <img className="fixed-width-3" alt="Fixed width" src="/img/vector-6.png" />
                          <div className="feature-title-2">Scrum lifecycle</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-153">
                    <div className="text-wrapper-101">Download Curriculum</div>
                    <img className="download-from-the" alt="Download from the" src="/img/vector-6.png" />
                  </div>
                </div>
                <div className="overlap-11">
                  <div className="frame-46">
                    <div className="frame-154">
                      <div className="frame-32">
                        <Component24
                          acknowledgeYourClassName="component-37"
                          className="component-24-instance"
                          componentClassName="component-25"
                          divClassName="component-28"
                          divClassName1="component-34"
                          divClassName2="component-37"
                          divClassName3="component-34"
                          divClassName4="component-34"
                          divClassNameOverride="component-29"
                          element="/img/vector-6.png"
                          elementClassName="component-46"
                          employEmpiricalClassName="component-34"
                          frameClassName="component-26"
                          frameClassName1="component-30"
                          frameClassName10="component-31"
                          frameClassName2="component-35"
                          frameClassName3="component-38"
                          frameClassName4="component-31"
                          frameClassName5="component-40"
                          frameClassName6="component-31"
                          frameClassName7="component-42"
                          frameClassName8="component-31"
                          frameClassName9="component-44"
                          frameClassNameOverride="component-27"
                          groupClassName="component-32"
                          groupClassName1="component-32"
                          groupClassName2="component-32"
                          groupClassNameOverride="component-32"
                          groupWrapperClassName="component-31"
                          groupWrapperClassNameOverride="component-31"
                          img="/img/vector-6.png"
                          imgClassName="component-39"
                          imgClassNameOverride="component-41"
                          property1="frame-40441"
                          vector="/img/vector-6.png"
                          vector1="/img/vector-6.png"
                          vector2="/img/vector-6.png"
                          vector3="/img/vector-6.png"
                          vector4="/img/vector-6.png"
                          vectorClassName="component-33"
                          vectorClassName1="component-43"
                          vectorClassName2="component-45"
                          vectorClassNameOverride="component-36"
                          vectorWrapperClassName="component-32"
                          vectorWrapperClassNameOverride="component-32"
                        />
                        <div className="frame-32">
                          <div className="frame-155">
                            <div className="frame-156">
                              <div className="frame-24">
                                <div className="frame-24">
                                  <div className="frame-157">
                                    <div className="text-wrapper-99">Career Path</div>
                                    <div className="text-wrapper-102">Individual</div>
                                  </div>
                                  <p className="text-wrapper-103">
                                    Scrum framework believes in ‘self-organization’. Making a career in Scrum as a Scrum
                                    Master will help an individualScrum framework believes in ‘self-organization’.
                                    Making a career in Scrum as a Scrum Master will help an individual Scrum framework
                                    believes in ‘self-organization’. Making a career in Scrum as a Scrum Master will
                                    help an individual
                                  </p>
                                </div>
                                <div className="frame-158">
                                  <div className="group-35">
                                    <div className="text-wrapper-104">Lorem ipsum</div>
                                    <div className="rectangle-13" />
                                    <div className="group-36">
                                      <div className="text-wrapper-104">Lorem ipsum</div>
                                      <div className="rectangle-13" />
                                      <img className="vector-10" alt="Vector" src="/img/vector-6.png" />
                                    </div>
                                    <div className="group-37">
                                      <div className="text-wrapper-104">Lorem ipsum</div>
                                      <div className="rectangle-13" />
                                      <img className="vector-10" alt="Vector" src="/img/vector-6.png" />
                                    </div>
                                    <img className="vector-10" alt="Vector" src="/img/vector-6.png" />
                                    <img className="vector-11" alt="Vector" src="/img/vector-6.png" />
                                  </div>
                                </div>
                              </div>
                              <img className="JOURNEY-OF-SCRUM" alt="Journey OF SCRUM" src="/img/vector-6.png" />
                            </div>
                          </div>
                          <div className="group-38">
                            <div className="frame-159">
                              <div className="frame-160">
                                <div className="frame-24">
                                  <div className="frame-161">
                                    <p className="upskilling">
                                      Upskilling Professionals of <br />
                                      Leading Organizations Globally
                                    </p>
                                    <p className="text-wrapper-105">
                                      Skillbook Academy’s CSM certification course will provide an overview of Scrum
                                      framework, scrum events, artifacts, core values and implementation techniques with
                                      case studies. The two day virtual course will engage learners in interactive
                                      assignments and real-life simulations to upgrade their knowledge and skills while
                                      having fun in tandem.
                                    </p>
                                    <div className="CTA">
                                      <div className="request-more">Request More Information</div>
                                    </div>
                                  </div>
                                </div>
                                <div className="group-39">
                                  <div className="overlap-group-11">
                                    <div className="group-40">
                                      <div className="group-41">
                                        <img className="paypal-logo" alt="Paypal logo" src="/img/vector-6.png" />
                                        <img className="cognizant-logo" alt="Cognizant logo" src="/img/vector-6.png" />
                                        <img className="ericsson-logo" alt="Ericsson logo" src="/img/vector-6.png" />
                                        <img className="acc-logo-black" alt="Acc logo black" src="/img/vector-6.png" />
                                      </div>
                                    </div>
                                    <div className="group-42">
                                      <div className="group-41">
                                        <img className="paypal-logo" alt="Paypal logo" src="/img/vector-6.png" />
                                        <img className="cognizant-logo" alt="Cognizant logo" src="/img/vector-6.png" />
                                        <img className="ericsson-logo" alt="Ericsson logo" src="/img/vector-6.png" />
                                        <img className="acc-logo-black" alt="Acc logo black" src="/img/vector-6.png" />
                                      </div>
                                    </div>
                                    <div className="group-43">
                                      <div className="group-41">
                                        <img className="paypal-logo" alt="Paypal logo" src="/img/vector-6.png" />
                                        <img className="cognizant-logo" alt="Cognizant logo" src="/img/vector-6.png" />
                                        <img className="ericsson-logo" alt="Ericsson logo" src="/img/vector-6.png" />
                                        <img className="acc-logo-black" alt="Acc logo black" src="/img/vector-6.png" />
                                      </div>
                                    </div>
                                    <div className="group-44">
                                      <div className="group-41">
                                        <img className="paypal-logo" alt="Paypal logo" src="/img/vector-6.png" />
                                        <img className="cognizant-logo" alt="Cognizant logo" src="/img/vector-6.png" />
                                        <img className="ericsson-logo" alt="Ericsson logo" src="/img/vector-6.png" />
                                        <img className="acc-logo-black" alt="Acc logo black" src="/img/vector-6.png" />
                                      </div>
                                    </div>
                                    <div className="group-45">
                                      <div className="group-41">
                                        <img className="paypal-logo" alt="Paypal logo" src="/img/vector-6.png" />
                                        <img className="cognizant-logo" alt="Cognizant logo" src="/img/vector-6.png" />
                                        <img className="ericsson-logo" alt="Ericsson logo" src="/img/vector-6.png" />
                                        <img className="acc-logo-black" alt="Acc logo black" src="/img/vector-6.png" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-162">
                        <div className="frame-163">
                          <div className="text-wrapper-57">FAQ</div>
                          <p className="have-questions-we-ve">
                            <span className="text-wrapper-106">Have questions? </span>
                            <span className="text-wrapper-30">We’ve got answers.</span>
                          </p>
                        </div>
                        <div className="frame-164">
                          <div className="frame-165">
                            <img className="line-3" alt="Line" src="/img/vector-20.png" />
                            <div className="frame-166">
                              <div className="frame-167">
                                <div className="ellipse-5" />
                                <ShopiconsRegular />
                              </div>
                              <p className="text-wrapper-107">
                                Why should you consider skillbook to become a CSM certified professional?
                              </p>
                            </div>
                            <img className="line-4" alt="Line" src="/img/vector-20.png" />
                            <div className="frame-166">
                              <div className="frame-167">
                                <div className="ellipse-5" />
                                <ShopiconsRegular />
                              </div>
                              <p className="text-wrapper-107">
                                Where will the certified scrum master training take place?
                              </p>
                            </div>
                            <img className="line-5" alt="Line" src="/img/vector-20.png" />
                            <div className="frame-166">
                              <div className="frame-167">
                                <div className="ellipse-5" />
                                <ShopiconsRegular />
                              </div>
                              <p className="text-wrapper-107">Do you offer any CSM certification training materials?</p>
                            </div>
                            <img className="line-6" alt="Line" src="/img/vector-20.png" />
                            <div className="frame-166">
                              <div className="frame-167">
                                <div className="ellipse-5" />
                                <ShopiconsRegular />
                              </div>
                              <p className="text-wrapper-107">
                                Is this a live training or will I watch pre-recorded videos?
                              </p>
                            </div>
                            <img className="line-7" alt="Line" src="/img/vector-20.png" />
                            <div className="frame-166">
                              <div className="frame-167">
                                <div className="ellipse-5" />
                                <ShopiconsRegular />
                              </div>
                              <p className="text-wrapper-107">
                                How does Scrum Alliance Differ from other certifying bodies?
                              </p>
                            </div>
                            <img className="line-8" alt="Line" src="/img/vector-20.png" />
                            <div className="frame-166">
                              <div className="frame-167">
                                <div className="ellipse-5" />
                                <ShopiconsRegular />
                              </div>
                              <p className="text-wrapper-108">
                                What are the potential benefits of ‘2 year Scrum Alliance membership?
                              </p>
                            </div>
                            <img className="line-9" alt="Line" src="/img/vector-20.png" />
                            <div className="frame-166">
                              <div className="frame-167">
                                <div className="ellipse-5" />
                                <ShopiconsRegular />
                              </div>
                              <p className="text-wrapper-108">
                                How can the CSM course benefit an individual or a profession?
                              </p>
                            </div>
                            <img className="line-10" alt="Line" src="/img/vector-20.png" />
                            <div className="frame-166">
                              <div className="frame-167">
                                <div className="ellipse-5" />
                                <ShopiconsRegular />
                              </div>
                              <p className="text-wrapper-108">What is next on the Scrum Master certification path?</p>
                            </div>
                            <img className="line-11" alt="Line" src="/img/vector-20.png" />
                            <div className="frame-166">
                              <div className="frame-167">
                                <div className="ellipse-5" />
                                <ShopiconsRegular />
                              </div>
                              <p className="text-wrapper-108">What’s the mode of payment?</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="group-46">
                    <div className="footers-wrapper">
                      <div className="footers">
                        <div className="frame-168">
                          <img className="group-47" alt="Group" src="/img/vector-6.png" />
                          <div className="frame-110">
                            <PropertyDefault />
                            <PropertyDefaultWrapper
                              className="property-default-instance"
                              propertyDefault="/img/vector-6.png"
                            />
                            <Property1Default3Wrapper />
                            <div className="element-wrapper">
                              <img className="element-3" alt="Element" src="/img/vector-6.png" />
                            </div>
                            <ImgWrapper className="property-1-default-instance" propertyDefault="/img/vector-6.png" />
                            <div className="tiktok-logo-on-black-wrapper">
                              <img
                                className="tiktok-logo-on-black"
                                alt="Tiktok logo on black"
                                src="/img/vector-6.png"
                              />
                            </div>
                          </div>
                        </div>
                        <img className="divider" alt="Divider" src="/img/vector-20.png" />
                        <div className="text-wrapper-109">Copyright 2022 Skillbook Academy</div>
                        <div className="frame-169">
                          <div className="text-wrapper-110">Terms and conditions</div>
                          <div className="text-wrapper-110">Privacy Policy</div>
                          <div className="text-wrapper-110">Refund Policy</div>
                        </div>
                        <div className="text-wrapper-111">We Accept:</div>
                        <div className="frame-170">
                          <img className="g-4" alt="G" src="/img/vector-6.png" />
                          <p className="this-site-is-SSL">
                            <span className="text-wrapper-112">This site is </span>
                            <span className="text-wrapper-113">SSL SECURED</span>
                          </p>
                        </div>
                        <img className="vector-12" alt="Vector" src="/img/vector-20.png" />
                        <img className="vector-13" alt="Vector" src="/img/vector-20.png" />
                        <div className="frame-171">
                          <div className="text-wrapper-114">Contact us:</div>
                        </div>
                        <div className="frame-172">
                          <div className="frame-173">
                            <div className="div-3">
                              <div className="text-wrapper-115">Courses</div>
                              <div className="text-wrapper-116">Certified ScrumMaster (CSM®)</div>
                              <p className="text-wrapper-116">Certified Scrum Product Owner (CSPO®)</p>
                              <div className="text-wrapper-116">Advanced Certified ScrumMaster (ACSM®)</div>
                              <div className="text-wrapper-116">Certified Scrum Professional (CSP®)</div>
                              <div className="text-wrapper-116">Leading SAFe®/SAFe® Agilist (SA)</div>
                            </div>
                            <div className="div-3">
                              <div className="text-wrapper-117">Our Resources</div>
                              <p className="text-wrapper-116">SAFe® Product Owner / Product Manager (POPM®)</p>
                              <div className="text-wrapper-116">SAFe® Scrum Master (SSM®)</div>
                              <p className="text-wrapper-116">SAFe® Lean Portfolio Management (LPM®)</p>
                              <div className="text-wrapper-116">SAFe® for Architects (Arch®)</div>
                              <div className="text-wrapper-116">SAFe® DevOps Practitioner (SDP®)</div>
                            </div>
                            <div className="div-3">
                              <div className="text-wrapper-117">Our Courses</div>
                              <div className="text-wrapper-116">SAFe® for Government (SGP®)</div>
                              <div className="text-wrapper-116">Agile Product Management (APM®)</div>
                              <div className="text-wrapper-116">SAFe® for Teams (SP®)</div>
                              <div className="text-wrapper-116">Team Kanban Practitioner (TKP®)</div>
                              <div className="text-wrapper-116">Kanban System Design (KMP1®)</div>
                            </div>
                          </div>
                          <div className="frame-174">
                            <div className="frame-173">
                              <div className="div-3">
                                <div className="text-wrapper-115">Our Company</div>
                                <div className="text-wrapper-116">Our Team</div>
                                <div className="text-wrapper-116">Company Vision</div>
                                <div className="text-wrapper-116">Our History</div>
                                <div className="text-wrapper-116">Instrutors</div>
                                <div className="text-wrapper-116">Instrutors</div>
                              </div>
                              <div className="div-3">
                                <div className="text-wrapper-115">Our Resources</div>
                                <div className="text-wrapper-116">The Blog</div>
                                <div className="text-wrapper-116">Downloads</div>
                                <div className="text-wrapper-116">Change Log</div>
                                <div className="text-wrapper-116">Slack Channel</div>
                                <div className="text-wrapper-116">Slack Channel</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="group-48">
                          <div className="frame-175">
                            <div className="frame-40">
                              <div className="frame-176">
                                <div className="text-wrapper-118">Post Registration Support</div>
                              </div>
                            </div>
                            <div className="frame-177">
                              <div className="frame-178">
                                <div className="frame-31">
                                  <img className="phone" alt="Phone" src="/img/vector-6.png" />
                                  <div className="text-wrapper-119">+91-(470) 646-6463</div>
                                </div>
                                <div className="frame-31">
                                  <img className="phone-2" alt="Phone" src="/img/vector-6.png" />
                                  <div className="text-wrapper-119">+91-(470) 646-6463</div>
                                </div>
                                <div className="frame-31">
                                  <img className="phone-3" alt="Phone" src="/img/vector-6.png" />
                                  <div className="text-wrapper-119">+91-(470) 646-6463</div>
                                </div>
                                <div className="frame-31">
                                  <img className="phone-4" alt="Phone" src="/img/vector-6.png" />
                                  <div className="text-wrapper-119">+91-(470) 646-6463</div>
                                </div>
                                <div className="frame-179">
                                  <img className="envelope" alt="Envelope" src="/img/vector-6.png" />
                                  <div className="text-wrapper-120">support@skillbook.com</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <img className="USA" alt="Usa" src="/img/vector-6.png" />
                          <img className="flag-of-canada" alt="Flag of canada" src="/img/vector-6.png" />
                          <img className="flag-united-kingdom" alt="Flag united kingdom" src="/img/vector-6.png" />
                          <img className="australia-flag" alt="Australia flag" src="/img/vector-6.png" />
                        </div>
                        <div className="group-49">
                          <div className="frame-175">
                            <div className="frame-40">
                              <div className="frame-176">
                                <div className="text-wrapper-118">Pre Registration Enquiry</div>
                              </div>
                            </div>
                            <div className="frame-178">
                              <div className="frame-31">
                                <img className="phone-5" alt="Phone" src="/img/vector-6.png" />
                                <div className="text-wrapper-119">+91-(470) 646-6463</div>
                              </div>
                              <div className="frame-31">
                                <img className="phone-6" alt="Phone" src="/img/vector-6.png" />
                                <div className="text-wrapper-119">+91-(470) 646-6463</div>
                              </div>
                              <div className="frame-31">
                                <img className="phone-7" alt="Phone" src="/img/vector-6.png" />
                                <div className="text-wrapper-119">+91-(470) 646-6463</div>
                              </div>
                              <div className="frame-31">
                                <img className="phone-8" alt="Phone" src="/img/vector-6.png" />
                                <div className="text-wrapper-119">+91-(470) 646-6463</div>
                              </div>
                              <div className="frame-179">
                                <img className="envelope-2" alt="Envelope" src="/img/vector-6.png" />
                                <div className="text-wrapper-120">support@skillbook.com</div>
                              </div>
                            </div>
                          </div>
                          <img className="USA-2" alt="Usa" src="/img/vector-6.png" />
                          <img className="flag-of-canada-2" alt="Flag of canada" src="/img/vector-6.png" />
                          <img className="flag-united-kingdom-2" alt="Flag united kingdom" src="/img/vector-6.png" />
                          <img className="australia-flag-2" alt="Australia flag" src="/img/vector-6.png" />
                        </div>
                        <div className="frame-180">
                          <div className="frame-181">
                            <div className="frame-182">
                              <div className="frame-183">
                                <div className="text-wrapper-121">Refer and win</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-184">
                            <div className="frame-185">
                              <div className="frame-186">
                                <div className="frame-183">
                                  <div className="text-wrapper-122">Subscribe to our Newsletter</div>
                                </div>
                              </div>
                            </div>
                            <div className="frame-187">
                              <div className="email-text-input-wrapper">
                                <div className="email-text-input">
                                  <div className="placeholder">*Enter your email</div>
                                  <button className="button">
                                    <div className="text-wrapper-123">Sign up</div>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-188">
                          <Paypal
                            property="/img/vector-6.png"
                            property1="variant-9"
                            propertyPrimaryClassName="paypal-instance"
                          />
                          <Paypal1
                            property="/img/vector-6.png"
                            property1="variant-7"
                            propertyPrimaryClassName="paypal-1"
                          />
                          <Paypal
                            property1="variant-3"
                            propertyPrimaryClassName="paypal-2"
                            propertyVariant="/img/vector-6.png"
                          />
                          <div className="discover-card-logo-wrapper">
                            <div className="discover-card-logo-2">
                              <img className="g-5" alt="G" src="/img/vector-6.png" />
                              <div className="ellipse-6" />
                            </div>
                          </div>
                          <Paypal
                            property1="primary"
                            propertyPrimary="/img/vector-6.png"
                            propertyPrimaryClassName="paypal-3"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="talk-to-our-learning">
                      <div className="overlap-group-12">
                        <div className="overlap-12">
                          <p className="text-wrapper-124">Talk to our Learning Consultant</p>
                        </div>
                        <div className="frame-189">
                          <div className="frame-190">
                            <div className="container-email">
                              <div className="input-field">
                                <div className="text-wrapper-125">Enter your name</div>
                              </div>
                            </div>
                            <div className="container-email">
                              <div className="input-field">
                                <div className="text-wrapper-125">Email address</div>
                              </div>
                            </div>
                            <div className="container-email">
                              <div className="input-field">
                                <div className="text-wrapper-125">Phone number</div>
                              </div>
                            </div>
                            <div className="input-field-wrapper">
                              <div className="input-field-2">
                                <div className="text-wrapper-126">Message</div>
                              </div>
                            </div>
                          </div>
                          <div className="CTA-2">
                            <div className="text-wrapper-127">Submit</div>
                          </div>
                        </div>
                        <p className="text-wrapper-128">Hello there! Hit us up with your questions.</p>
                        <p className="text-wrapper-129">We are happy to help ! :)</p>
                      </div>
                      <img className="vector-14" alt="Vector" src="/img/vector-6.png" />
                    </div>
                  </div>
                  <div className="frame-191">
                    <div className="group-50">
                      <div className="frame-192">
                        <div className="frame-193">
                          <p className="text-wrapper-130">Talk to our Learning Consultant</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img className="home-page-final" alt="Home page final" src="/img/home-page-final-2-1.png" />
          </div>
        </div>
        <div className="frame-194">
          <div className="heading-wrapper">
            <div className="heading">
              <div className="text-wrapper-131">Skillbook Academy</div>
              <p className="digital-branding-web">Digital Branding &amp; Web Design for an Edtech platform</p>
            </div>
          </div>
          <div className="group-51">
            <div className="UIX-wrapper">
              <div className="UIX">
                <div className="frame-195">
                  <div className="frame-196">
                    <div className="group-52">
                      <div className="overlap-group-13">
                        <img className="line-12" alt="Line" src="/img/line-18.svg" />
                        <img className="line-13" alt="Line" src="/img/line-19.svg" />
                      </div>
                    </div>
                    <div className="text-wrapper-132">EdTech</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-wrapper-133">Industry</div>
          </div>
        </div>
        <div className="overlap-13">
          <div className="rectangle-14" />
          <img className="home-page-final-2" alt="Home page final" src="/img/home-page-final-2.png" />
          <div className="frame-197">
            <div className="text-wrapper-134">Objective</div>
            <p className="the-objective-of-the">
              The objective of the revamp is to build credibility, bring out the value proposition offered by the
              organisation to their users, build a user friendly interface with a crisp &amp; clear communication, make
              the navigation simple to bring more users to register on the platform.
            </p>
          </div>
        </div>
        <div className="element-4">
          <div className="overlap-14">
            <div className="text-wrapper-135">Visual Language</div>
            <div className="text-wrapper-135">Visual Language</div>
          </div>
          <div className="frame-198">
            <div className="overlap-group-14">
              <div className="overlap-15">
                <div className="text-wrapper-136">Aa</div>
                <div className="text-wrapper-137">Manrope</div>
              </div>
              <div className="text-wrapper-138">AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz</div>
            </div>
          </div>
          <div className="frame-199">
            <div className="frame-200">
              <div className="frame-201">
                <div className="frame-202">
                  <div className="text-wrapper-139">Orange</div>
                  <div className="frame-203">
                    <div className="text-wrapper-140">#FB5741</div>
                  </div>
                </div>
              </div>
              <div className="frame-204">
                <div className="frame-14">
                  <div className="text-wrapper-141">Beige</div>
                  <div className="frame-203">
                    <div className="text-wrapper-142"> #FFEEDA</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-205">
              <div className="frame-206">
                <div className="frame-14">
                  <div className="text-wrapper-139">Vivid Blue</div>
                  <div className="frame-203">
                    <div className="text-wrapper-140">#0E3B65</div>
                  </div>
                </div>
              </div>
              <div className="frame-207">
                <div className="frame-14">
                  <div className="text-wrapper-139">Black</div>
                  <div className="frame-203">
                    <div className="text-wrapper-140">#0D0D0D</div>
                  </div>
                </div>
              </div>
              <div className="frame-208">
                <div className="frame-14">
                  <div className="text-wrapper-141">Mint</div>
                  <div className="frame-203">
                    <div className="text-wrapper-142"> #F4FDF8</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img className="image" src="/image.png"/>
        <div className="csinc-wrapper">
          <img className="csinc" alt="Csinc" src="/img/csinc03-1.png" />
        </div>
        <div className="group-53">
          <div className="mask-group-wrapper">
            <img className="mask-group" alt="Mask group" src="/img/mask-group.png" />
          </div>
        </div>
        <div className="frame-209">
          <p className="element-s-efforts">
            99xStudio&#39;s efforts have resulted in beautifully designed pages and components. The team has effectively
            planned and executed the project, demonstrating responsiveness and flexibility towards changes and
            suggestions.&#34;
          </p>
          <div className="frame-210">
            <div className="frame-211">
              <div className="frame-212">
                <div className="text-wrapper-143">Jay Roy</div>
                <div className="text-wrapper-144">Director, Skillbook Academy</div>
              </div>
            </div>
            <a
              className="text-wrapper-145"
              href="https://clutch.co/profile/99xstudio#review-2144028"
              rel="noopener noreferrer"
              target="_blank"
            >
              Read the complete review
            </a>
          </div>
        </div>
        <img className="oqag" alt="Oqag" src="/img/oq3a8g1-1.png" />
        <div className="overlap-16">
          <img
            className="flying-two-side"
            alt="Flying two side"
            src="/img/flying-two-side-iphone-x-mockup-template-64d2d02bcc8e687dc8fc335.png"
          />
          <img className="element-5" alt="Element" src="/img/14-1.png" />
          <div className="frame-213">
            <div className="rectangle-15" />
            <div className="frame-214">
              <div className="frame-215">
                <div className="frame-216">
                  <div className="text-wrapper-146">Our Approach</div>
                </div>
              </div>
              <div className="frame-217">
                <div className="frame-218">
                  <p className="help-users-make">
                    Help users make informed choice : The communication &amp; the design is around providing
                    comprehensive course information for the users explaining the key stats, who the course is intended
                    for, course schedules, Previews, reviews &amp; ratings , Instructor credentials &amp; learning
                    pathways.
                  </p>
                  <p className="text-wrapper-147">
                    Engage the users to stay on the site : we used simple &amp; seamless micro interactions to make the
                    site dynamic &amp; not a static page, seamless transitions between the sections.
                  </p>
                </div>
                <div className="frame-219">
                  <p className="address-the-question">
                    Address the question of Why Skillbook Academy over other platforms - The interface explains the
                    users on the skillbook advantage of live courses, clear metrics &amp; statistics in-terms of proven
                    success rate, mentorship, student testimonials, Instructor Credentials &amp; much more.
                  </p>
                  <p className="text-wrapper-147">
                    User friendly interface: Approach follows Intuitive navigation, clean design, mobile responsive
                    layouts, Clear call to action buttons, course previews &amp; more.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-220">
          <div className="rectangle-16" />
          <div className="frame-221">
            <div className="text-wrapper-148">User Persona</div>
            <div className="frame-222">
              <p className="name-michael-age">
                Name: Michael
                <br />
                Age: 35
                <br />
                Occupation: Software Developer
              </p>
              <p className="michael-is-an">
                Michael is an experienced software developer who has been working on various projects using traditional
                project management methods. He&#39;s noticed that many companies are transitioning to Agile
                methodologies, particularly Scrum, to enhance project efficiency and collaboration. Michael wants to
                stay relevant in his field by learning Scrum practices and principles to better align with industry
                trends.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
